<template>
  <div class="gonggao">
    <div class="gonggao-con">
      <a class="zhengji" href="#part1"></a>
      <a class="yaoqiu" href="#part2"></a>
      <a class="jiangxiang" href="#part3"></a>
      <a class="zhuyi" href="#part4"></a>
      <div id="part1"></div>
      <div id="part2"></div>
      <div id="part3"></div>
      <div id="part4"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="less">
.gonggao {
  height: 3413px;
  background-color: #f1f1f1;
  .gonggao-con {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    background: no-repeat url(../assets/gonggao.jpg);
    position: relative;
    .zhengji {
      width: 150px;
      height: 70px;
      display: inline-block;
      position: absolute;
      left: 310px;
      top: 769px;
    }
    #part1 {
      height: 20px;
      width: 20px;
      position: absolute;
      left: 652px;
      top: 990px;
    }
    .yaoqiu {
      width: 150px;
      height: 70px;
      display: inline-block;
      position: absolute;
      left: 497px;
      top: 769px;
    }
    #part2 {
      height: 20px;
      width: 20px;
      position: absolute;
      left: 652px;
      top: 1300px;
    }
    .jiangxiang {
      width: 150px;
      height: 70px;
      display: inline-block;
      position: absolute;
      left: 684px;
      top: 769px;
    }
    #part3 {
      height: 20px;
      width: 20px;
      position: absolute;
      left: 652px;
      top: 2672px;
    }
    .zhuyi {
      width: 150px;
      height: 70px;
      display: inline-block;
      position: absolute;
      left: 871px;
      top: 768px;
    }
    #part4 {
      height: 20px;
      width: 20px;
      position: absolute;
      left: 652px;
      top: 2889px;
    }
  }
}
</style>
